/* lg-размер (<=1199px) */
@media (max-width: 1199px) {
  header {
    padding: 20px 16px !important;
  }
  /* CSS для ширины от 992px до 1199px */
  .card__container {
    flex-wrap: wrap;
  }
  .card-small {
    width: 221.33px !important;
    img {
      width: 221.33px;
    }
    .card-small_content {
      h6 {
        padding: 0 32px;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

/* sm-размер (<=768px) */
@media (max-width: 991px) {
    .swiper{
        width: 1600px;
    }
  .main__info-container {
    padding: 32px 16px !important;
  }
  .footer-box_image {
    img {
      width: 280px;
      height: 252px;
    }
  }
  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .main__info {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px !important;
      line-height: 26px !important;
    }
    ul {
      li {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .main__info_image {
    img {
      width: 288px;
      height: 380px;
    }
    margin-top: 32px;
    justify-content: center !important;
  }
  .block__body-img {
    img {
      width: 340px;
      height: 340px;
    }
  }
  .block__body-content {
    padding: 48px 32px !important;
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }
    ul {
      li {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .qr_container {
    img {
      width: 78px;
      height: 78px;
    }
  }
  /* CSS для ширины от 576px до 767px */
}

/* xs-размер (<=575px) */
@media (max-width: 732px) {
  body {
    min-width: 400px;
  }
  #main-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #bottom-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  header {
    position: sticky;
    top: 0;
    z-index: 999;
    background: white;
  }
  .header-nav_buttons {
    display: none !important;
  }
  .footer-box_content {
    justify-content: center !important;
    align-items: center !important;
    h1 {
      text-align: center !important;
    }
    p {
      text-align: center !important;
    }
  }
  .card__container {
    max-width: 400px;
    justify-content: center;
    .card-small {
      width: 335px !important;
    }
  }
  .footer-box_image {
    display: none;
  }

  /* CSS для ширины до 575px (включительно) */
}
@media (max-width: 575px) {
  /* CSS для ширины до 575px (включительно) */
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 32px;
    /* or 114% */

    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.01em;
  }
  button {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;
  }
  .main__info {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      display: flex;
      align-items: center;
    }
    ul {
      li {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &_image {
      img {
        width: 288px;
        height: 380px;
      }
    }
  }
  .card_container-swiper {
    width: 270px !important;
    // height: 402px !important;
    // min-height: 466px;
    &.small {
      width: 288px !important;
      height: 392px !important;
    }
    &.customer {
      width: 278px !important;
      height: 416px !important;
    }
  }
  .footer-box {
    padding: 16px 16px !important;
  }
  .card_container-swiper {
    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
    }
    li{
        font-weight: 400;
        font-size: 16px !important;
        line-height: 20px !important;
    }
  }
  .customers-container {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    h1 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      text-align: start !important;
      width: 50%;
    }
    p {
      margin: 0;
      margin-bottom: 24px;
    }
    img {
      margin-bottom: -6px;
    }
  }
}
