.content__box {
  padding: 80px 0 80px 0;
  overflow: hidden;
  .swiper__wraper-freemode {
    overflow: visible;
  }
  .icon-container {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 12px;
  }
}
