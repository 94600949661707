._flag-container {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 100px;
  margin-bottom: 8px;
}
.customers-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
  }
  p{
    margin: 0;
    margin-bottom: 24px;
  }
  img {
    margin-bottom: -6px;
  }
}
