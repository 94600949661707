.card__container {
  gap: 12px;
}
.card_container-swiper {
  width: 340px !important;
  height: 466px !important;
  // min-height: 466px;
  &.small {
    height: 426px !important;
  }
  &.customer{
    height: 368px !important;
  }
}
.card {
  background: #ffffff;
  border-radius: 20px;
  // width: 340px !important;
  // min-height: 466px;
  height: -webkit-fill-available;
  padding: 32px;
  text-align: start;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    /* or 119% */
    letter-spacing: -0.02em;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 24px;
  }
  ul {
    text-align: start;
    // margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    li {
      margin: 0;
      padding: 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }
  }
  &-small {
    height: -webkit-fill-available;
    padding: 32px 0px 0px;

    width: 381.33px;
    // height: 426px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &_content {
      display: flex;
      gap: 24px;
      flex-direction: column;
      align-items: center;
    }
    h6 {
      margin: 0;
      max-width: 300px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      /* or 140% */

      text-align: center;

      color: #000000;
    }
    &-customer_comment {
      padding: 32px;
      height: -webkit-fill-available;
      width: auto;
      // height: 368px;
      background: #ffffff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .card-small_content {

        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
      }
      h6 {
        margin: 0;
        max-width: 300px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        /* or 140% */

        text-align: start;

        color: #000000;
      }
      p {
        margin: 0;
        text-align: start;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
