.block {
  .block__body {
    flex-wrap: wrap;
    .block__body-content {
      h3 {
        font-size: 18px;
        line-height: 26px;
      }
      .title__secondary {
        font-size: 32px;
        line-height: 38px;
      }
      .text__secondary {
        font-size: 18px;
        line-height: 24px;
      }
      text-align: start;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }
      ul {
        // margin: 0;
        padding: 0;
        list-style: none;
        li {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
    .block__body-img {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 54px 0px;
    }
  }
}
