.main__info {
  min-width: 360px;
  text-align: start;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    /* or 142% */

    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 24px;
    flex-shrink: 0;
    li {
        padding-bottom: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      color: #000000;
      svg{padding-right: 10px;}

    }
    li::before{
      display: none;
    }
  }
}

.main__info_image {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
