.howitworks__box {
  padding: 80px 0px;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      text-align: center;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: 32px;
      max-width: 580px;
    }
  }
}

