.footer-box {
  margin: 96px 0 65px 0;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 34px 60px 48px 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  &_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h1 {
      text-align: start;
      margin-bottom: 16px;
    }
    p {
      text-align: start;
      margin-bottom: 48px;
    }
  }
  &_image{
    flex: 1; 

    img{
        position: absolute;
        bottom: 0;

        right: 0;
    }
  }
}
