.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
  
}
h1{    
  margin: 0;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 52px
}
h3{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  letter-spacing: -0.01em;
}
.qr_container{
  display: inline-flex;
  border: 1px solid #000000;
  border-radius: 16px;
  padding: 20px;
}
a{
  text-decoration: none;
  color: white;
}
ul{
  margin-left: 1em
}
li::before{
  content: "·";
  display: inline-block;
  margin-left: -1em; /* same as padding-left set on li */
  width: 1em; /* same as padding-left set on li */
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
}
._list-divider {
  margin-right: 10px;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
}
._icon-number{
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 10px; */
  background: #000000;
  border-radius: 100px;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title__secondary{
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 56px;
  /* identical to box height, or 108% */
  
  letter-spacing: -0.02em;
  
  color: #000000;
}

.title__text{
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */


  color: #000000;

}
